import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
// import "lightbox.js-react/dist/index.css";
import { Helmet } from "react-helmet";
import { useGetGallaryListQuery } from "../products/productSlice";
import { ImgesData } from "../home/proImgs/ImgesData";

import img1 from "../../assets/img/photo-gallery/1.jpg";
import img2 from "../../assets/img/photo-gallery/2.jpg";
import img3 from "../../assets/img/photo-gallery/3.jpg";
import img4 from "../../assets/img/photo-gallery/4.jpg";
import img5 from "../../assets/img/photo-gallery/5.jpg";
import img6 from "../../assets/img/photo-gallery/6.jpg";
import img7 from "../../assets/img/photo-gallery/7.jpg";
import img8 from "../../assets/img/photo-gallery/8.jpg";
import img9 from "../../assets/img/photo-gallery/9.jpg";
import img10 from "../../assets/img/photo-gallery/10.jpg";
import img11 from "../../assets/img/photo-gallery/11.jpg";
import img12 from "../../assets/img/photo-gallery/12.jpg";
import img13 from "../../assets/img/photo-gallery/13.jpg";
import img14 from "../../assets/img/photo-gallery/14.jpg";
import img15 from "../../assets/img/photo-gallery/15.jpg";
import img16 from "../../assets/img/photo-gallery/16.jpg";
import img17 from "../../assets/img/photo-gallery/17.jpg";
import img18 from "../../assets/img/photo-gallery/18.jpg";
import img19 from "../../assets/img/photo-gallery/19.jpg";
import img20 from "../../assets/img/photo-gallery/20.jpg";
import img21 from "../../assets/img/photo-gallery/21.jpg";
import img22 from "../../assets/img/photo-gallery/22.jpg";
import img23 from "../../assets/img/photo-gallery/23.jpg";
import img24 from "../../assets/img/photo-gallery/24.jpg";
import { useTranslation } from "react-i18next";

const photoData = [
  { id: "1", url: img1 },
  { id: "2", url: img2 },
  { id: "3", url: img3 },
  { id: "4", url: img4 },
  { id: "5", url: img5 },
  { id: "6", url: img6 },
  { id: "7", url: img7 },
  { id: "8", url: img8 },
  { id: "9", url: img9 },
  { id: "10", url: img10 },
  { id: "11", url: img11 },
  { id: "12", url: img12 },
  { id: "13", url: img13 },
  { id: "14", url: img14 },
  { id: "15", url: img15 },
  { id: "16", url: img16 },
  { id: "17", url: img17 },
  { id: "18", url: img18 },
  { id: "19", url: img19 },
  { id: "20", url: img20 },
  { id: "21", url: img21 },
  { id: "22", url: img22 },
  { id: "23", url: img23 },
  { id: "24", url: img24 },
];

function PhotoGallery() {
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetGallaryListQuery();
  return (
    <>
      <Helmet>
        <title>Photo Gallery | Max Export</title>
        <meta name="keyword" content="Max Export" />
        <meta name="description" content="Max Export" />
      </Helmet>
      <Breadcrumb title="Photo Gallery" t={t} />
      <section className="photoGallery p-30">
        <div className="container">
          {isLoading && (
            <div className="preloaderCount">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {/* Gallery */}
          <div className="row">
            {/* Gallery */}

            <div className="row">
              {photoData.map((item) => {
                return (
                  <div className="col-lg-4" key={item.id}>
                    <div className="photoCard">
                      <img
                        src={item.url}
                        className="img-fluid"
                        alt="Max Export"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Gallery */}
          </div>

          {/* <div
            className="modal fade exampleModalPhoto"
            id="exampleModalPhoto"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <img src={ImgesData[0].url} />
                <div id="carouselExampleIndicators" className="carousel slide">
                  <div className="carousel-inner">
                    <div
                      style={{ borderTop: "2px solid gray" }}
                      className="carousel-item active"
                    >
                      <img src={ImgesData[0].url} />
                    </div>
                    <div className="carousel-item">
                      <img src={ImgesData[0].url} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default PhotoGallery;
