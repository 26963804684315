import React from "react";
import img1 from "../../../assets/img/instagram1.jpg";
import img2 from "../../../assets/img/instagram2.jpg";
import img3 from "../../../assets/img/instagram3.jpg";
import img4 from "../../../assets/img/instagram4.jpg";
import img5 from "../../../assets/img/instagram5.jpg";
import { Link } from "react-router-dom";
import { AiFillInstagram } from "react-icons/ai";

const Instagram = () => {
  return (
    <>
      <div className="instagramSec">
        <div className="container-fluid">
          <div className="fisherman-content">
            <h3>Follow Us On Instagram</h3>
          </div>
          <div className="instagramCard">
            <div className="instagramItem">
              <Link to="/">
                <img src={img1} alt="Ethos" className="img-fluid" />
                <div className="icon"><AiFillInstagram /></div>

              </Link>
            </div>
            <div className="instagramItem">
              <Link to="/">
                <img src={img2} alt="Ethos" className="img-fluid" />
                <div className="icon"><AiFillInstagram /></div>
              </Link>
            </div>
            <div className="instagramItem">
              <Link to="/">
                <img src={img3} alt="Ethos" className="img-fluid" />
                <div className="icon"><AiFillInstagram /></div>
              </Link>
            </div>
            <div className="instagramItem">
              <Link to="/">
                <img src={img4} alt="Ethos" className="img-fluid" />
                <div className="icon"><AiFillInstagram /></div>
              </Link>
            </div>
            <div className="instagramItem">
              <Link to="/">
                <img src={img5} alt="Ethos" className="img-fluid" />
                <div className="icon"><AiFillInstagram /></div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instagram;
