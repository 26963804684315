import React from "react";
import aboutIMG from "../../../assets/img/serviceList/8.jpg";

const About = () => {
  return (
    <>
      <section className="aboutHome">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="aboutImg">
                <img src={aboutIMG} alt="About" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="aboutText">
                <h3>About Us</h3>
                <p>
                  Exquisitely crafted pieces of art on an entirely different
                  canvas, Qaaleen’s exquisite rugs are just the thing to
                  embellish your space with in expressly opulence. Whether it is
                  a creation that is reminiscent of a royal heritage, or an
                  artistically designed silk rug, Qaaleen offers a remarkable,
                  diverse and one-of a kind variety to discerning customers.
                </p>
                <p>
                  Exquisitely crafted pieces of art on an entirely different
                  canvas, Qaaleen’s exquisite rugs are just the thing to
                  embellish your space with in expressly opulence. Whether it is
                  a creation that is reminiscent of a royal heritage, or an
                  artistically designed silk rug, Qaaleen offers a remarkable,
                  diverse and one-of a kind variety to discerning customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
