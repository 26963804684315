import React, { useEffect } from "react";

import etg from "../../../assets/img/brands/etg.png";
import kynoch from "../../../assets/img/brands/kynoch.png";
import falcon from "../../../assets/img/brands/falcon.png";
import oemff from "../../../assets/img/brands/oemff.png";
import { Link } from "react-router-dom";
import { useListAllBrandQuery } from "../../products/productSlice";
import axios from "axios";
import { AiFillCar } from "react-icons/ai";
import { RiArrowGoForwardLine } from "react-icons/ri";
import { FaMoneyBill } from "react-icons/fa";
import { BsShieldLockFill } from "react-icons/bs";

const imgs = [etg, kynoch, falcon, oemff];

function Shipping() {
  // const { data, isLoading, error } = useListAllBrandQuery()

  return (
    <>
      <section className="brandsSec" style={{background:"#f5f2f0"}}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="keyInfo">
                <div className="icon">
                  <AiFillCar />
                </div>
                <div className="content">
                  <h5>Free Shipping</h5>
                  <p>
                    Free same day delivery within  on orders above 5000 INR.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="keyInfo">
                <div className="icon">
                  <RiArrowGoForwardLine />
                </div>
                <div className="content">
                  <h5>Full Refund</h5>
                  <p>
                    Didn't like it?. No problem! simply return it within 7 days
                    and get a full refund.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="keyInfo">
                <div className="icon">
                  <FaMoneyBill />
                </div>
                <div className="content">
                  <h5>Debit & Credit Card</h5>
                  <p>
                    Don't have a Credit Card? We accept any debit card issued in
                    
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="keyInfo">
                <div className="icon">
                  <BsShieldLockFill />
                </div>
                <div className="content">
                  <h5>100% Secure Payment</h5>
                  <p>
                    We ensure 100% secure payment through accredited 3rd party.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Shipping;
