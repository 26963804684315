import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import home1 from "../../../assets/img/banner/banner1.jpg";
import home2 from "../../../assets/img/banner/banner2.jpg";
import home3 from "../../../assets/img/banner/banner3.jpg";
import aboutIMG from "../../../assets/img/about.webp";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      console.log("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* <section className="bannerSection">
        <Slider {...settings}>
          {data &&
            data.slice(0, 2).map((item) => {
              return (
                item?.image?.url && (
                  <Link to={item?.url || ""} key={item._id}>
                    <div className="bannerItem">
                      <img src={item?.image.url} className="img-fluid" />
                      <div className="bannerText">
                        <h2>Max Export</h2>
                      </div>
                    </div>
                  </Link>
                )
              );
            })}
        </Slider>
      </section> */}

      <section className="bannerSection">
        <Slider {...settings}>
          <div className="bannerItem">
            <img src={home3} alt="Banner" className="img-fluid" />
            <div className="bannerDetail">
              <div className="bannerContent">
                <div className="title">
                  <h6>Qaleen</h6>
                  <h3>
                    <strong>New Arrivals collection</strong>
                  </h3>
                  <h5>Redefine Your Space with the latest looks</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Accusantium eaque expedita fuga nam delectus vitae
                    perspiciatis quos rem cum laborum suscipit eum consequuntur,
                    voluptatum quod harum voluptas minima magni ducimus?
                  </p>
                </div>
                <div className="shopNow">
                  <Link to="/products">Shop Now</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bannerItem">
            <img src={home2} alt="Banner" className="img-fluid" />
            <div className="bannerDetail">
              <div className="bannerContent">
                <div className="title">
                  <h6>Qaleen</h6>
                  <h3>
                    <strong>Luxury at your feet</strong>
                  </h3>
                  <h5>Discover Finest Rugs to Elevate your Home!</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Accusantium eaque expedita fuga nam delectus vitae
                    perspiciatis quos rem cum laborum suscipit eum consequuntur,
                    voluptatum quod harum voluptas minima magni ducimus?
                  </p>
                </div>
                <div className="shopNow">
                  <Link to="/products">Shop Now</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bannerItem">
            <img src={home1} alt="Banner" className="img-fluid" />
            <div className="bannerDetail">
              <div className="bannerContent">
                <div className="title">
                  <h6>Qaleen</h6>
                  <h3>
                    <strong>Trending Now!</strong>
                  </h3>
                  <h5>Discover Finest Rugs to Elevate your Home!</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Accusantium eaque expedita fuga nam delectus vitae
                    perspiciatis quos rem cum laborum suscipit eum consequuntur,
                    voluptatum quod harum voluptas minima magni ducimus?
                  </p>
                </div>
                <div className="shopNow">
                  <Link to="/products">Shop Now</Link>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>
    </>
  );
}

export default Banner;
