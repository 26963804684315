import React from "react";
import service1 from "../../../assets/img/service1.webp";
import service2 from "../../../assets/img/service2.webp";
import service3 from "../../../assets/img/service3.webp";
import service4 from "../../../assets/img/service4.jpg";

import { Link } from "react-router-dom";

import "./ServiceList.css";
function ServiceList() {
  return (
    <>
      <section className="serviceList">
        <div className="container">
          <div className="fisherman-content">
            <h6 className="brandName">Max Export</h6>
            <h3 className="text-white">Shop By Collection</h3>
            <p className="text-white">
              Exquisitely crafted pieces of art on an entirely different canvas,
              Qaaleen’s exquisite rugs are just the thing to embellish your
              space with in expressly opulence. Whether it is a creation that is
              reminiscent of a royal heritage, or an artistically designed silk
              rug, Qaaleen offers a remarkable, diverse and one-of a kind
              variety to discerning customers.
            </p>
          </div>
          <div className="serviceItemInfo service">
            <div className="serviceItem">
              <div className="serviceImg">
                <img src={service1} alt="Image" />
              </div>
              <div className="serviceItemText">
                <h5>Modern</h5>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceImg">
                <img src={service2} alt="Image" />
              </div>
              <div className="serviceItemText">
                <h5>Classic</h5>
              </div>
            </div>
            <div className="serviceItem">
              <div className="serviceImg">
                <img src={service3} alt="Image" />
              </div>
              <div className="serviceItemText">
                <h5>Traditional </h5>
              </div>
            </div>
            <div className="serviceItem ">
              <div className="serviceImg">
                <img src={service4} alt="Image" />
              </div>
              <div className="serviceItemText">
                <h5>Geometrical</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceList;
