import React from "react";
import { FaAddressBook } from "react-icons/fa";
import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Ri24HoursFill } from "react-icons/ri";

function ContactAddress({ t }) {
  return (
    <>
      <div className="connectSec">
        <div className="row">
          <div className="col-sm-6 col-lg-6">
            <div className="feature-box text-center">
              <div className="feature-box-content">
                <BsFillPhoneFill />
                <h4>{t("Phone Number")}</h4>
                <p>
                  <a href="tel:+91-8851746286">+91-8851746286</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6">
            <div className="feature-box text-center">
              <div className="feature-box-content">
                <MdEmail />
                <h4>{t("E-mail Address")}</h4>

                <p>
                  <a href="mailto:Max Export@gmail.com">Max Export@gmail.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-12">
            <div className="feature-box text-center">
              <div className="feature-box-content">
                <FaAddressBook />
                <h4>{t("Address")}</h4>
                <p className="text-white">
                  98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends
                  Colony, New Delhi-110025
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactAddress;
