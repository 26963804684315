import React from "react";

function AboutChoose({ t }) {
  return (
    <>
      <section className="featuresSection">
        <div className="container-fluid">
          <div className="fisherman-content">
            <h6 className="brandName">
              Max Export Agrow Private Limited (KAPL)
            </h6>

            <h3>{t("why choose us")}</h3>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="feature-box bg-white">
                <i className="icon-shipped" />
                <div className="feature-box-content">
                  <h5>{t("Best Quality")}</h5>
                  <p>{t("inabout")}</p>
                </div>
                {/* End .feature-box-content */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}
            <div className="col-lg-4">
              <div className="feature-box bg-white">
                <i className="icon-us-dollar" />
                <div className="feature-box-content">
                  <h5>{t("Customer Care")}</h5>
                  <p>{t("about-2")}</p>
                </div>
                {/* End .feature-box-content */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}
            <div className="col-lg-4">
              <div className="feature-box bg-white">
                <i className="icon-online-support" />
                <div className="feature-box-content">
                  <h5> {t("Support 24/7")}</h5>
                  <p>{t("cont")}</p>
                </div>
                {/* End .feature-box-content */}
              </div>
              {/* End .feature-box */}
            </div>
            {/* End .col-lg-4 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </section>
    </>
  );
}

export default AboutChoose;
