import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import "./Menus.css";
import { useGetMenuListQuery } from "../../../components/products/productSlice";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { base_url } from "../../../server";

import shape1 from "../../../assets/img/shape1.png";
import shape2 from "../../../assets/img/shape2.png";
import shape3 from "../../../assets/img/shape3.png";
import shape4 from "../../../assets/img/shape4.png";
import shape5 from "../../../assets/img/shape5.png";

function Menus() {
  const isLogin = window.localStorage.getItem("isLogin");
  // const { data, isSuccess } = useGetMenuListQuery()

  const [data, setdata] = useState(null);

  const getData = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/category/filter`,
      { withCredentials: true }
    );
    setdata(res.data);
  };

  const [categoryData, setCategoryData] = useState(null);
  const baseUrl = base_url();
  const getCategoryData = async () => {
    try {
      const res = await axios.get(`${baseUrl}category/public`, {
        withCredentials: true,
      });
      setCategoryData(res.data);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
  };
  console.log("menu data..", categoryData);
  useEffect(() => {
    getData();
    getCategoryData();
  }, []);

  return (
    <>
      <div
        className="collapse navbar-collapse mean-menu"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav m-0 p-0">
          <li className="nav-item">
            <NavLink to="/" className="nav-link">
              Home
            </NavLink>
            {/* <div className="dropdown-menu">
              <div className="row">
                <div className="col-lg-3">
                  <div className="menuList">
                    <h4>Products</h4>
                    <ul>
                      <li className="nav-item">
                        <Link to="/products" className="nav-link">
                          <FiChevronRight /> Products
                        </Link>
                      </li>


                      <li className="nav-item">
                        <Link to="/seller" className="nav-link">
                          <FiChevronRight /> Seller
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/privacy-policy" className="nav-link">
                          <FiChevronRight /> Privacy Policy
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/delivery-policy" className="nav-link">
                          <FiChevronRight /> Delivery Policy
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/careers" className="nav-link">
                          <FiChevronRight /> Careers
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="menuList">
                    <h4>Pages</h4>
                    <ul>
                      <li className="nav-item">
                        <Link to="/cart" className="nav-link">
                          <FiChevronRight /> Cart
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/checkout" className="nav-link">
                          <FiChevronRight /> Checkout
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/track-order" className="nav-link">
                          <FiChevronRight /> Track Order
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/sell/sell-with-us" className="nav-link">
                          <FiChevronRight /> Sell With US
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/shop/create" className="nav-link">
                          <FiChevronRight /> Shop Create
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/faq" className="nav-link">
                          <FiChevronRight /> Faq
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/testimonial" className="nav-link">
                          <FiChevronRight /> Testimonial
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="menuList">
                    <ul>
                      <li className="nav-item">
                        <Link to="/terms-of-use" className="nav-link">
                          <FiChevronRight /> Terms and Condition
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/about" className="nav-link">
                          <FiChevronRight /> About
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/contact" className="nav-link">
                          <FiChevronRight /> Contact
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/blog" className="nav-link">
                          <FiChevronRight /> Blog
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/blog-detail" className="nav-link">
                          <FiChevronRight /> Blog Detail
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/photo-gallery" className="nav-link">
                          <FiChevronRight /> Photo Gallery
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/video-gallery" className="nav-link">
                          <FiChevronRight /> Video Gallery
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="menuList">
                    <h4>Login</h4>
                    <ul>
                      <li className="nav-item">
                        <Link to="/login" className="nav-link">
                          <FiChevronRight /> Login
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/registration" className="nav-link">
                          <FiChevronRight /> Registration
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/reset" className="nav-link">
                          <FiChevronRight /> Reset
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </li>
          <li className="nav-item">
            <NavLink to="/about" className="nav-link">
              About
            </NavLink>
          </li>

          <li className="nav-item dropdown">
            <NavLink to="/products" className="nav-link">
              Collections
            </NavLink>
            <ul className="dropdown-menu">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-xs-3">
                  <div className="dropSec">
                    <h4>Category</h4>
                    <div className="dropItem">
                      <strong>Our Category</strong>
                      <ul>
                        {categoryData?.map((item, i) => {
                          return (
                            <li className="nav-item" key={i}>
                              <Link
                                to={`/product/category/${item.uid}/${item?.slug}`}
                              >
                                {item?.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="dropItem">
                      <strong>Oriental</strong>
                      <ul>
                        <li>
                          <Link to={`/`}>ReineSeide</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Seide</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Wolle</Link>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="dropItem">
                      <strong>Vintage</strong>
                      <ul>
                        <li>
                          <Link to={`/`}>AlleTeppiche</Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-xs-2">
                  <div className="dropSec">
                    <h4>Material</h4>
                    <div className="dropItem">
                      <ul>
                        <li>
                          <Link to={`/`}>Artificial Silk</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Bamboo Silk</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Cotton</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Silk</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Wool</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Wool/Silk</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-xs-2">
                  <div className="dropSec">
                    <h4>Production type</h4>
                    <div className="dropItem">
                      <ul>
                        <li>
                          <Link to={`/`}>Handknotted</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Handloom</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Handtufted</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Handwoven</Link>
                        </li>
                        <li>
                          <Link to={`/`}>Machine knotted</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-xs-2">
                  <div className="dropSec">
                    <h4>Size</h4>
                    <div className="dropItem">
                      <ul>
                        <li>
                          <Link to={`/`}>{"<"}100x150 cm</Link>
                        </li>
                        <li>
                          <Link to={`/`}>100x150 cm</Link>
                        </li>
                        <li>
                          <Link to={`/`}>140x200 cm</Link>
                        </li>
                        <li>
                          <Link to={`/`}>160x230 cm</Link>
                        </li>
                        <li>
                          <Link to={`/`}>200x300 cm</Link>
                        </li>
                        <li>
                          <Link to={`/`}>{">"}200x300 cm</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-xs-3">
                  <div className="dropSec">
                    <h4>Color</h4>
                    <div className="dropItem">
                      <ul className="color">
                        <li>
                          <span style={{ backgroundColor: "black" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "blue" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "brown" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "coral" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "cyan" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "gold" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "green" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "gray" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "lavender" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "magenta" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "maroon" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "olive" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "purple" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "red" }}></span>
                        </li>

                        <li>
                          <span style={{ backgroundColor: "teal" }}></span>
                        </li>
                        <li>
                          <span style={{ backgroundColor: "violet" }}></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="dropSec">
                    <h4>Shape</h4>
                    <div className="dropItem">
                      <ul className="color">
                        <li>
                          <img src={shape1} alt="Shape" className="img-fluid" />
                        </li>
                        <li>
                          <img src={shape2} alt="Shape" className="img-fluid" />
                        </li>
                        <li>
                          <img src={shape3} alt="Shape" className="img-fluid" />
                        </li>
                        <li>
                          <img src={shape4} alt="Shape" className="img-fluid" />
                        </li>
                        <li>
                          <img src={shape5} alt="Shape" className="img-fluid" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </li>

          {/* {data &&
            data?.map((item, i) => {
              return (
                <li className="nav-item perent" key={i}>
                  <NavLink
                    to={`product/category/${item.title.uid}/${item.title.slug}`}
                    className="nav-link"
                  >
                    {item.title.name}
                  </NavLink>

                  {item?.Submenu?.length > 0 && (
                    <ul className={`dropdown-menu dropAgro ${i}`}>
                      <div className="row">
                        <div className="col-lg-3 width-100pr">
                          <div
                            className="menuList"
                            style={{ paddingTop: "0px" }}
                          >
                            <ul>
                              {item.Submenu &&
                                item.Submenu.map((item) => {
                                  return (
                                    <li className="nav-item" key={item._id}>
                                      <Link
                                        to={`product/category/${item.uid}/${item.slug}`}
                                        className="nav-link sub_menu_Text"
                                      >
                                        <FiChevronRight /> {item.name}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ul>
                  )}
                </li>
              );
            })} */}

          <li className="nav-item">
            <NavLink to="/" className="nav-link">
              Videos
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/" className="nav-link">
              Support
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink to="/contact" className="nav-link">
              Contact
            </NavLink>
          </li>
          {isLogin === true && (
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Services
              </Link>
            </li>
          )}
          {/* <li className="nav-item">
            <Link to="/view-all-brands">
              View Brands
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/allSellers">
              View Sellers
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  );
}

export default Menus;
