import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetFeaturedProductQuery } from "../../products/productSlice";
import FeaturedProduct from "./featured-product/FeaturedProduct";
import QuiekViewModal from "../../../pages/QueikViewModal/QuiekViewModal";
import { featuredDB } from "../../../rki-data/category";
import axios from "axios";
import { base_url } from "../../../server";
import { useTranslation } from "react-i18next";

import product1 from "../../../assets/img/serviceList/1.webp";
import product2 from "../../../assets/img/serviceList/2.webp";
import product3 from "../../../assets/img/serviceList/3.webp";
import product4 from "../../../assets/img/serviceList/4.webp";
import product5 from "../../../assets/img/serviceList/5.webp";
import product6 from "../../../assets/img/serviceList/6.jpg";
import product7 from "../../../assets/img/serviceList/7.jpg";
import product8 from "../../../assets/img/serviceList/8.jpg";

const featuredData = [
  {
    id: "1",
    price: "3400",
    dimension: "202 X 141",
    title: "Tribal Ghom",
    url: product1,
  },
  {
    id: "2",
    price: "1222",
    dimension: "234 X 164",
    title: "Hand Loom",
    url: product2,
  },
  {
    id: "3",
    price: "4000",
    dimension: "269 X 247",
    title: "Grace",
    url: product3,
  },
  {
    id: "4",
    price: "2999",
    dimension: "269 X 237",
    title: "Kent",
    url: product4,
  },
  {
    id: "5",
    price: "1111",
    dimension: "294 X 244",
    title: "Willington",
    url: product5,
  },
  {
    id: "6",
    price: "3400",
    dimension: "202 X 141",
    title: "Be Memorable-I",
    url: product6,
  },
  {
    id: "7",
    price: "1222",
    dimension: "234 X 164",
    title: "Sky Over Mountain-II",
    url: product7,
  },
  {
    id: "8",
    price: "4000",
    dimension: "269 X 247",
    title: "Sleeping Panda",
    url: product8,
  },
];

function FeaturedProducts() {
  const [data, setData] = useState();
  const [error, seterror] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const baseUrl = base_url();
  const getdata = async (page) => {
    setisLoading(true);
    try {
      // const res = await axios.get(`https://onlineparttimejobs.in/api/product/page/${page}&${12}`, { withCredentials: true })
      const res = await axios.get(`${baseUrl}product/page/0&10000`, {
        withCredentials: true,
      });
      setData(res.data);
      setisLoading(false);
    } catch (error) {
      console.log("Server Error !");
      seterror(true);
      setisLoading(false);
    }
  };
  useEffect(() => {
    getdata(0);
  }, []);

  const [modelDataId, setModelDataId] = useState(null);
  const setProduct_id = (id) => {
    setModelDataId(id);
  };

  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id);
    setShow(true);
  };

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  }, [data]);

  return (
    <>
      <section className="productsSection ">
        <div className="container-fluid">
          <div className="row featuredRow">
            <div className="col-lg-12">
              <div className="fishermanHeader ">
                <div className="fisherman-content">
                  <h6 className="brandName">Max Export</h6>
                  <h3>{t("special products")}</h3>
                </div>
              </div>
            </div>

            <FeaturedProduct
              data={sortedData}
              getFeaturedPro={getdata}
              isLoading={isLoading}
              error={error}
              handleShow={handleShow}
              setProduct_id={setProduct_id}
            />

            {/* <div className="serviceItemInfo d-none">
              <div className="row">
                {featuredData.map((item) => {
                  return (
                    <div className="col-md-3" key={item.id}>
                      <div className="serviceItem">
                        <div className="serviceItemImg">
                          <img
                            src={item.url}
                            className="img-fluid"
                            alt={item.title}
                            title={item.title}
                          />
                        </div>
                        <div className="serviceItemText">
                          <h5>
                            <Link to="/products">
                              {item.title}
                            </Link>
                          </h5>
                            <span>{item.dimension}</span>
                          <p>₹{item.price}.00</p>
                          <div className="buyNowInfo">
                            <Link className="btn buyNow cart" to="/">
                              Add to Cart
                            </Link>
                            <Link className="btn buyNow" to="/">
                              Buy Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </div>
        </div>

        {/* {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )} */}
      </section>
    </>
  );
}

export default FeaturedProducts;
