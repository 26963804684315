import React from "react";
import titan from "../../../assets/img/9JPoGjTANa-44.webp";
import imachining from "../../../assets/img/nyTqGeG5jc-GR13Sb.webp";
import RightShortIntro from "./right-short-intro/RightShortIntro";
import LeftShortIntro from "./left-short-intro/LeftShortIntro";

const ShortInfo = () => {
  return (
    <>
      <RightShortIntro
        data={{
          url: titan,
          title: "The art of expression",
          desc: "Exquisitely crafted pieces of art on an entirely different canvas, Qaaleen’s exquisite rugs are just the thing to embellish your space with in expressly opulence. ",
        }}
      />
      <LeftShortIntro
        data={{
          url: imachining,
          title: "The art of expression",
          desc: "Whether it is a creation that is reminiscent of a royal heritage, or an artistically designed silk rug, Qaaleen offers a remarkable, diverse and one-of a kind variety to discerning customers.",
        }}
      />

      
    </>
  );
};

export default ShortInfo;
