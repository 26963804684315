import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import brandLogo from "../../../assets/img/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { BiPhoneCall } from "react-icons/bi";
import {
  AiOutlineUser,
  AiOutlineHeart,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { BsHandbag } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import "./SearchBox.css";
import { PiShoppingCartSimple } from "react-icons/pi";

import { useRef } from "react";
import {
  useGetCartQuery,
  getProducts,
  setUpdatedProducts,
  setObjectVal,
  useGetWishListQuery,
  setCartCalc,
} from "../../../components/products/productSlice";
import axios from "axios";
import Menus from "../menu/Menus";

function SearchBox({ showMenu, val }) {
  const userid = window.localStorage.getItem("user_id");
  const isLogin = window.localStorage.getItem("isLogin");
  const nums = window.localStorage.getItem("callNum");
  const [click, setClick] = useState(false);

  // const { data, isSuccess, isFetching } = useGetCartQuery(userid)

  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  // const { data: value } = useGetWishListQuery(userid)
  const [trendSearch, setTrendSearch] = useState(null);
  const inputRef = useRef();
  const handleRef = useRef();
  const dispatch = useDispatch();

  const [valuewish, setValuewish] = useState();

  const getDatas = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/user/wishlist/${userid}`
    );
    setValuewish(res.data);
  };

  useEffect(() => {
    // getDatas()
  }, []);

  const [state, setState] = useState();

  const handleShow = () => {
    setShow(!show);
  };

  const { updatedProducts, products, cartLengh, WishLengh } = useSelector(
    (item) => {
      return item.productList;
    }
  );

  // useEffect(() => {
  //   if (data) {
  //     dispatch(getProducts(data.cart.products))
  //     dispatch(setObjectVal(data))
  //     if (isLogin === 'true') {
  //       dispatch(setUpdatedProducts(data.cart))
  //     }
  //     dispatch(setCartCalc(data))
  //   }
  // }, [data])

  const handleClick = () => {
    let currentVal = inputRef.current.value.toLowerCase();
    const res = axios.get(
      `https://onlineparttimejobs.in/api/product/search/${inputRef.current.value}`
    );
    navigate(`/products/${currentVal}`);
    currentVal = "";
    setShow(false);
  };

  const getData = async () => {
    const resData = await axios.get(
      `https://onlineparttimejobs.in/api/product/trendingSearches`
    );
    setTrendSearch(resData?.data);
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!handleRef.current) {
      return;
    }
    let handler = (e) => {
      if (show === true) {
        if (!handleRef?.current?.contains(e.target)) {
          setShow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return function () {};
  }, []);

  // useEffect(() => {
  //   if (isLogin === 'true') {
  //     setState(data)
  //   } else {
  //     setState([])
  //   }
  // }, [isFetching])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <>
      <section className="searchSec">
        <div className="container-fluid">
          <div className="searchItem">
            <div className="mainLogo logo">
              <Link to="/">
                {/* <img
                  src={val?.icon?.url ? val?.icon?.url : brandLogo}
                  alt="logo"
                /> */}
                <span>Max</span> Export
              </Link>
              <div className="bar">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <FaBars onClick={showMenu} />
                </button>
              </div>
            </div>
            <div className="abaris-nav">
              <nav className="navbar navbarItem navbar-expand-md">
                <Menus />
              </nav>
            </div>
            <div className="alignRight">
              <div className="searchCard">
                <div className="searchInf">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onClick={handleShow}
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="rightSearchInfo">
                    <div className="searchIcon">
                      <BiSearch onClick={handleClick} />
                      {/* <button type="button" ></button> */}
                    </div>
                  </div>
                </div>

                {show && (
                  <div className="treandingSec" id="DropShow" ref={handleRef}>
                    <div className="trendingItem d-none">
                      <h5 className="trendingTitle">Trending</h5>
                      <ul>
                        {trendSearch &&
                          trendSearch?.map((item, i) => {
                            return (
                              <li key={i}>
                                <Link
                                  to={`/products/${item.query}`}
                                  className="nav-link"
                                >
                                  {" "}
                                  {item.query}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                    <div className="trendingItem">
                      <h5 className="trendingTitle">Discover more</h5>
                      <ul>
                        <li>
                          <Link to="/">Geometrical</Link>
                        </li>
                        <li>
                          <Link to="/">Traditional</Link>
                        </li>
                        <li>
                          <Link to="/">Classic</Link>
                        </li>
                        <li>
                          <Link to="/">Modern</Link>
                        </li>
                        
                      </ul>
                      <AiOutlineCloseCircle
                        className="closeBtnDrop"
                        onClick={handleShow}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="searchIcons">
                {/* <div className="callDetail">
                  <BiPhoneCall />
                </div> */}
                <div className="searchIconsItem">
                  <ul>
                    <li>
                      <Link to="/myAccount">
                        <AiOutlineUser />
                      </Link>
                    </li>
                    <li className="countWish">
                      <Link to="/wishlist">
                        <AiOutlineHeart />
                        {WishLengh > 0 ? (
                          <span className="count">{WishLengh}</span>
                        ) : (
                          ""
                        )}
                        {WishLengh > 0 ? (
                          <span className="count">{WishLengh}</span>
                        ) : (
                          ""
                        )}
                        {/* {data?.cart.products > 0 && <span className="wishCircle">{}</span>} */}
                      </Link>
                    </li>
                    <li className="countParent">
                      <Link to="/cart">
                        <PiShoppingCartSimple />
                        {cartLengh > 0 ? (
                          <span className="count">{cartLengh}</span>
                        ) : (
                          ""
                        )}
                        {/* {isLogin === 'true' ? <span className="count">{state?.cart?.products.length}</span> : <span className="count">{updatedProducts?.length}</span>} */}
                        {/* {isLogin === true ? data?.cart?.products?.length > 0 &&  <span className="count">{window.localStorage.getItem('cartLength')}</span> :
                        updatedProducts?.length > 0 ? <span className="count">{updatedProducts?.length}s</span> : ''} */}
                        {/* {isLogin === 'true' ? cartLengh > 0 && <span className="count"></span> :
                        updatedProducts?.length > 0 ? <span className="count"></span> : ''} */}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SearchBox;
