import React from "react";
import touchBG from "../../../assets/img/banner/craftmenship-banner_2000x2000.webp";
import buttonBG from "../../../assets/img/team-v1-shape1.png";

const GetTouch = () => {
  return (
    <>
      <section className="getTouchSec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>Drop us a message for any query</h3>
                <p>If you have an idea, we would love to hear about it.</p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="getTouch">
                <form className="row" action="#">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="contact-name">
                        Your Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="contact-name"
                        name="name"
                        required
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="contact-email">
                        Your E-mail
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="contact-email"
                        name="email"
                        required
                        placeholder="Enter Email"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="contact-email">
                        Phone
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="contact-email"
                        name="phone"
                        required
                        placeholder="Phone number"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="contact-message">
                        Your Message
                      </label>
                      <textarea
                        cols={30}
                        rows={4}
                        id="contact-message"
                        className="form-control"
                        name="message"
                        required
                        placeholder="Enter Your Message"
                      />
                    </div>
                    <div className="form-footer mb-0">
                      <button type="button" className="buttonBG border-0">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.431846591954!2d77.2597101!3d28.55679230000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1730454525844!5m2!1sen!2sin"
                width={"100%"}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetTouch;
